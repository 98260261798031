<p-toast></p-toast>
<p-toast key="main"></p-toast>
<p-contextMenu #cmu [model]="contextMenuUsers"></p-contextMenu>
<p-contextMenu #cmr [model]="contextMenuRole"></p-contextMenu>


<div class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card">
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Settings</h2>
  </div>

</div>

<div class="m-4">

  <p-tabView>
    
    <p-tabPanel header="Members & Roles" *ngIf="currentUser.role?.name == 'admin'">
    <section
      class="px-4 lg:px-6"
      
    >
      <div class="surface-border">
        <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Users & Roles</h2>
        <p class="mt-0 mb-5 text-700 font-normal text-base">
          You can easily manage your team on this page
        </p>
      </div>
      <section
        class="flex flex-wrap gap-3 py-6 justify-content-between shadow-3 surface-card"
      >
   
        <div class="flex-shrink-0 w-15rem p-4">
          <h3 class="mb-4 mt-0 text-900 font-medium text-xl">Users</h3>
          <p class="mb-4 mt-0 text-700 font-normal text-base">
            Manage your users in this project
          </p>
          <button
            pButton
            pRipple
            label="Register a User"
            class="w-auto"
            (click)="showUserRegisterDialog()"
          ></button>
        </div>
        <div class="overflow-x-scroll p-4">
          <p-table
            #dt1
            [value]="(members$ | async) || []"
            [tableStyle]="{ 'min-width': '60rem' }"
            [rowHover]="true"
            [scrollable]="true"
            scrollHeight="50vh"
            [(contextMenuSelection)]="selectedMember"
            [contextMenu]="cmu"
            [paginator]="true"
            [rows]="100"
            [rowsPerPageOptions]="[100, 200, 500]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['first_name', 'last_name', 'email', 'company_name']"
          >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal1($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of userColumns" [pSortableColumn]="col.field">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-member>
              <tr style="cursor: pointer;" (click)="showUserDetailsDialog(member, $event)" [pContextMenuRow]="member">
                <td *ngFor="let col of userColumns">

                  <div class="flex align-items-center gap-3">
                    <div>
                      <p class="mt- mb-2 font-medium text-md text-color-primary">
                        {{ member[col.field] }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-secondary"
                    (click)="menu.toggle($event); storeRowDetail(member); $event.stopPropagation();"
                  ></button>
                  <p-menu
                    #menu
                    appendTo="body"
                    [popup]="true"
                    [model]="items"
                  ></p-menu>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td
                  [attr.colspan]="this.getNumberofColumns()"
                  class="align-content-center"
                >
                  <p-progressBar
                    styleClass="align-self-center"
                    *ngIf="isLoadingUsers"
                    mode="indeterminate"
                  >
                  </p-progressBar>
                  <div *ngIf="!isLoadingUsers">
                    <p>
                      There are no records to show. You might be disconnected or offline.
                    </p>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </section>
      <section
        class="flex flex-wrap gap-3 mt-4 py-6 justify-content-between surface-card shadow-3"
      >
        <div class="flex-shrink-0 w-15rem p-4">
          <h3 class="mb-4 mt-0 text-900 font-medium text-xl">Roles</h3>
          <p class="mb-4 mt-0 text-700 font-normal text-base">
            Edit roles in this project
          </p>
          <button 
            pButton 
            pRipple 
            label="Add a role" 
            class="w-auto"
            (click)="showCreateRoleDialog()">
          </button>
        </div>
        <div class="overflow-x-scroll p-4">
          <p-table
            #dt
            [value]="(roles$ | async) || []"
            [tableStyle]="{ 'min-width': '60rem' }"
            [rowHover]="true"
            [(contextMenuSelection)]="selectedRole"
            [contextMenu]="cmr"
            [paginator]="true"
            [rows]="100"
            [rowsPerPageOptions]="[100, 200, 500]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['name', 'description']"
          >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of roleColumns"  [pSortableColumn]="col.field">
                  <span class="font-semibold text-sm text-color-secondary">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </span>
                </th>
                <th>
                  <span class="font-semibold text-sm text-color-secondary"
                    >Users</span
                  >
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
              <tr style="cursor: pointer;" (click)="showRoleDetailDialog(role, $event)" [pContextMenuRow]="role">
                <td *ngFor="let col of roleColumns">
                  <div class="flex align-items-center gap-3">
                    <div>
                      <p class="mt- mb-2 font-medium text-md text-color-primary">
                        {{ role[col.field] }}
                      </p>
                    </div>
                  </div>
                <td>
                  <p
                    class="mt-0 mb-0 font-normal text-base text-color-secondary"
                  >
                    {{ role.users }}
                  </p>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-secondary"
                    (click)="menu2.toggle($event); storeRowDetail(role); $event.stopPropagation();"
                  ></button>
                  <p-menu
                    #menu2
                    appendTo="body"
                    [popup]="true"
                    [model]="roleItems"
                  ></p-menu>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td
                  [attr.colspan]="this.getNumberofColumns()"
                  class="align-content-center"
                >
                  <p-progressBar
                    styleClass="align-self-center"
                    *ngIf="isLoadingRoles"
                    mode="indeterminate"
                  >
                  </p-progressBar>
                  <div *ngIf="!isLoadingRoles">
                    <p>
                      There are no records to show. You might be disconnected or offline.
                    </p>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </section>
    </section>
  </p-tabPanel>
  <p-tabPanel header="Profile">
    <div class="surface-section px-4 py-4 md:px-1 lg:px-2">
      <div class="surface-border">
        <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Profile</h2>
        <p class="mt-0 mb-5 text-700 font-normal text-base">
          Manage your profile here
        </p>
      </div>
      <section
        class="flex flex-wrap gap-3 px-6 py-6 justify-content-between shadow-3 surface-card"
      >
      <div class="grid">
        <form
            [formGroup]="this.userService.updateUserForm"
            (ngSubmit)="updateProfile(currentUser.id)"
            >
            <div class="col-12 lg:col-12">
              <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 md:col-6">
                  <label for="first_name" class="font-medium text-900"
                    >First Name</label
                  >
                  <input
                    id="first_name"
                    type="text"
                    pInputText
                    formControlName="first_name"
                    [(ngModel)]="currentUser.first_name"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                    this.userService.userForm.get('first_name')?.invalid &&
                    (this.userService.userForm.get('first_name')?.touched ||
                        this.userService.userForm.get('first_name')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>First Name</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="last_name" class="font-medium text-900"
                    >Last Name</label
                  >
                  <input
                    id="last_name"
                    type="text"
                    pInputText
                    formControlName="last_name"
                    [(ngModel)]="currentUser.last_name"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      this.userService.userForm.get('last_name')?.invalid &&
                      (this.userService.userForm.get('last_name')?.touched ||
                        this.userService.userForm.get('last_name')?.dirty)
                    "
                  >
                    <small id="last_name-help" class="p-error block"
                      ><strong>Last Name</strong> is required</small
                      >
                    </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="username" class="font-medium text-900">Username</label>
                  <input
                    id="username"
                    type="text"
                    pInputText
                    formControlName="username"
                    [(ngModel)]="currentUser.username"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                    !this.userService.userForm.get('username')?.valid &&
                      this.userService.userForm.get('username')?.touched
                      "
                  >
                    <small id="last_name-help" class="p-error block"
                      ><strong>Username</strong> is required</small
                    >
                  </div>
                </div>
      
                <div class="field mb-4 col-12 md:col-6">
                  <label for="email" class="font-medium text-900">Email</label>
                  <input 
                    id="email" 
                    type="email" 
                    pInputText 
                    formControlName="email" 
                    [(ngModel)]="currentUser.email"/>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                    this.userService.userForm.get('email')?.invalid &&
                    (this.userService.userForm.get('email')?.touched ||
                      this.userService.userForm.get('email')?.dirty)
                      "
                  >
                    <small id="last_name-help" class="p-error block"
                    ><strong>Email</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="role" class="font-medium text-900 pr-3">Role:</label>
      
                  <p-tag>{{ currentUser.role.name }}</p-tag>
                </div>
                <div class="field mb-4 col-12 md:col-6" formGroupName="customer">
                  <label for="customer" class="font-medium text-900 pr-3">Customer:</label>
                  <div *ngIf="currentUser.customer.company_name; else noCustomer">
                    <p-tag>{{ currentUser.customer.company_name }}</p-tag>
                  </div>
                  <ng-template #noCustomer>
                    <p-tag severity="info">No Customer Linked</p-tag>
                  </ng-template>
                 
                </div>
      
                <div class="col-12">
                  <button
                    [loading]="loading"
                    pButton
                    pRipple
                    type="submit"
                    label="Save Changes"
                    class="w-auto mt-3"
                    ></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
      
    </p-tabPanel>
  
  <!-- <p-tabPanel header="Quotes Configuration">
    <section
    class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border"
    style="overflow: auto; height: 60vh"
    >
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Active Orders</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">
          Manage active orders
        </p>
      </div>
      <section
        class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border"
      >
        <div class="">
          <p-table
            [value]="members"
            [tableStyle]="{ 'min-width': '80rem' }"
            [rowHover]="true"
            [scrollable]="true"
            scrollHeight="50vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <span class="font-semibold text-sm text-color-secondary"
                    >Name</span
                  >
                </th>
                <th>
                  <span class="font-semibold text-sm text-color-secondary"
                    >Email Address</span
                  >
                </th>
                <th>
                  <span class="font-semibold text-sm text-color-secondary"
                    >Joined Date</span
                  >
                </th>
                <th>
                  <span class="font-semibold text-sm text-color-secondary"
                    >Last Active</span
                  >
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-member >
              <tr style="cursor: pointer;" (click)="showUserDetailsDialog(member, $event)" >
                <td>
                  <div class="flex align-items-center gap-3">
                    <div>
                      <p
                        class="mt-0 mb-2 font-medium text-lg text-color-primary"
                      >
                        {{ member.name }}
                      </p>
                      <p
                        class="mt-0 mb-2 font-normal text-base text-color-secondary"
                      >
                        {{ member.username }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p
                    class="mt-0 mb-2 font-normal text-base text-color-secondary"
                  >
                    {{ member.email }}
                  </p>
                </td>
                <td>
                  <p
                    class="mt-0 mb-2 font-normal text-base text-color-secondary"
                  >
                    {{ member.date }}
                  </p>
                </td>
                <td>
                  <p
                    class="mt-0 mb-2 font-normal text-base text-color-secondary"
                  >
                    {{ member.active }}
                  </p>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-secondary"
                    (click)="menu.toggle($event); $event.stopPropagation();"
                  ></button>
                  <p-menu
                    #menu
                    appendTo="body"
                    [popup]="true"
                    [model]="items"
                  ></p-menu>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </section>
    </section>
  </p-tabPanel> -->
  <!-- <p-tabPanel header="Header 3"> Content 3 </p-tabPanel> -->
</p-tabView>
</div>
<p-confirmDialog header="Delete"></p-confirmDialog>
