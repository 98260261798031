<div
  class="flex flex-column min-h-screen h-screen max-h-screen"
  *ngIf="userService.currentUser$ | async as currentUser"
>
  <div
    class="navbar py-3 px-6 flex align-items-center justify-content-between relative lg:static lg:shadow-3 shadow-0 bg-orange-100"
    style="min-height: 64px; z-index: 100"
  >
    <a [routerLink]="['/']">
      <img
        src="assets/images/logo.png"
        alt="Image"
        height="52px"
        class="mr-0 lg:mr-6"
      />
    </a>
    
    <!-- when mobile -->
    <a
      pRipple
      class="cursor-pointer block lg:hidden text-black"
      pStyleClass="@next"
      enterClass="hidden"
      leaveToClass="hidden"
      [hideOnOutsideClick]="true"
    >
      <div *ngIf="user$ | async">
        <i class="pi pi-bars text-4xl"></i>
      </div>
    </a>

    <div
      class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none lg:border-none bg-orange-100"
    >
      <ul
        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
        *ngIf="user$ | async"
      >
        <div *ngFor="let menuItem of menuItems">
          <li
            class="h-full"
            *hasRoles="menuItem.rolesWithAccess; for: currentUser"
            (click)="toggleMenu(menuItem)"
          >
            <div
              *ngIf="menuItem.label == 'Home' || menuItem.label == 'Settings'"
            >
              <a
                pRipple
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-800 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                routerLinkActive="border-blue-500 text-blue-500"
                [routerLink]="menuItem.routerLink"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="pi {{ menuItem.icon }} mr-2"></span>
                <span class="font-medium">{{ menuItem.label }}</span>
              </a>
            </div>
            <div *ngIf="menuItem.childMenuItem">
              <a
                pRipple
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-800 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                pStyleClass="@next"
                enterClass="hidden"
                enterActiveClass="scalein"
                leaveToClass="hidden"
                leaveActiveClass="fadeout"
                [hideOnOutsideClick]="true"
              >
                <span class="pi {{ menuItem.icon }} mr-2"></span>
                <span class="font-medium">{{ menuItem.label }}</span>
                <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
              </a>
              <ul
                class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-orange-100 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800"
              >
                <li
                  class="relative"
                  *ngFor="let childMenuItem of menuItem.childMenuItem"
                >
                  <a
                    *hasRoles="childMenuItem.rolesWithAccess; for: currentUser"
                    pRipple
                    class="flex p-3 align-items-center text-gray-800 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                    pStyleClass="@next"
                    enterClass="hidden"
                    enterActiveClass="scalein"
                    leaveToClass="hidden"
                    leaveActiveClass="fadeout"
                    [routerLink]="childMenuItem.routerLink"
                    [hideOnOutsideClick]="true"
                  >
                    <i class="pi {{ childMenuItem.icon }} mr-2"></i>
                    <span>{{ childMenuItem.label }}</span>
                    <div *ngIf="childMenuItem.childSubItem" class="ml-auto">
                      <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                    </div>
                  </a>

                  <ul
                    *ngIf="childMenuItem.childSubItem"
                    class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-orange-100 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800"
                  >
                    <li *ngFor="let childSubItem of childMenuItem.childSubItem">
                      <a
                        *hasRoles="
                          childSubItem.rolesWithAccess;
                          for: currentUser
                        "
                        pRipple
                        class="flex p-3 align-items-center text-gray-800 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                        [routerLink]="childSubItem.routerLink"
                      >
                        <i class="pi {{ childSubItem.icon }} mr-2"></i>
                        <span>{{ childSubItem.label }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </ul>
      

      <ul
        *ngIf="(user$ | async)"
        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 lg:border-top-none"
      >
        <li *ngIf="userRole != 'basic-vermaak'">
          <a
            pRipple
            [routerLink]="['cart']"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-800 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
          >
            <i
              class="pi pi-shopping-cart text-base lg:text-2xl mr-2 lg:mr-0"
            ></i>
            <span class="block lg:hidden font-medium">Cart</span>
            <span class="ml-3 font-bold">{{ cartLength }}</span>
          </a>
        </li>
       
        <li class="lg:border-top-none">
          <a
            pRipple
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-800 hover:text-white hover:bg-red-600 font-medium border-round cursor-pointer transition-colors transition-duration-150"
            (click)="authenticationService.logout()"
          >
            <i class="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0"></i>
            <span class="block lg:hidden font-medium"> Sign Out </span>
          </a>
        </li>
      </ul>
      
    </div>
    <div *ngIf="userRole == 'basic-vermaak'">
      <img
      src="../../assets/images/ZP Logo.png"
      height="52px"
      class="right-0"
      alt=""
      />
    </div>
  </div>

  <div class="flex-grow-1 overflow-y-auto">
    <router-outlet> </router-outlet>
  </div>

  <div
    class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
  >
    <span class="text-600">© Backflow Integration {{ currentYear }}</span>
  </div>
</div>
