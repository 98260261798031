<div class="surface-card shadow-3">
  <div class="m-4">
    <div class="flex flex-row justify-content-between mb-2">
      <div class="mb-2">
        <h4>Order Type</h4>
        <span>{{ data.order_type }}</span>
      </div>
      <div class="mb-2">
        <h4>Order Number</h4>
        <span>{{ data.order_number }}</span>
      </div>
      <div class="mb-2">
        <h4>Purchase Order</h4>
        <span>{{ data.purchase_order }}</span>
      </div>
      <div>
        <h4>Rows</h4>
        <span>{{ row_count }}</span>
      </div> 
    </div>
    <p-divider></p-divider>
    <p-table
      [value]="data.orders"
      [paginator]="true"
      [rows]="5"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">
          <span class="font-semibold text-md text-color-secondary ">{{
            col.header
          }}</span>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-orders>
      <tr>
        <td *ngFor="let col of columns">
          <div class="flex align
          -items-center gap-3">
            <div>
              <p class="mt- mb-2 font-medium text-md text-color-primary">
                {{ orders[col.field] }}
              </p>
            </div>
          </div>
    </ng-template>
    </p-table>
  </div>
</div>
<div>
  <button
    pButton
    type="button"
    icon="pi pi-check"
    label="Submit Order"
    [loading]="loading"
    class="mr-4"
    (click)="submitOrder()"
  >
  </button>
  <button
    pButton
    type="button"
    icon="pi pi-times"
    label="Close"
    class="p-button-danger"
    (click)="closeDialog()"
  >

  </button>
</div>
