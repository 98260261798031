import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './main-application/settings/settings.component';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleriaModule } from 'primeng/galleria';
import { AvatarModule } from 'primeng/avatar';
import { FilesizePipe } from './_helpers/filesize.pipe';
import { TruncateFilenamePipe } from './_helpers/truncate-filename.pipe';
import { LoginComponent } from './auth/login/login.component';
import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { PrimeModule } from './prime.module';
import { RegisterUserComponent } from './main-application/settings/register-user/register-user.component';
import { DialogService } from 'primeng/dynamicdialog';
import { HomeComponent } from './main-application/home/home.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { QuotesTableComponent } from './main-application/quotes-table/quotes-table.component';
import { PasswordModule } from 'primeng/password';
import { MessagesModule } from 'primeng/messages';
import { DialogModule } from 'primeng/dialog';
import { QuoteDetailComponent } from 'src/app/main-application/quote-detail/quote-detail.component';
import { UserDetailsComponent } from './main-application/settings/user-details/user-details.component';
import { UpdateUserComponent } from './main-application/settings/update-user/update-user.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UpdateRoleComponent } from './main-application/settings/update-role/update-role.component';
import { RoleDetailsComponent } from './main-application/settings/role-details/role-details.component';
import { CreateRoleComponent } from './main-application/settings/create-role/create-role.component';
import { ToastModule } from 'primeng/toast';
import { RouterModule } from '@angular/router';
import { RefreshComponent } from './refresh/refresh.component';
import { ProductComponent } from './main-application/product/product.component';
import { UpdateProductComponent } from './main-application/update-product/update-product.component';
import { CreateProductComponent } from './main-application/create-product/create-product.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenerateQuoteComponent } from './main-application/generate-quote/generate-quote.component';
import { CartComponent } from './main-application/cart/cart.component';
import { ProfileComponent } from './main-application/profile/profile.component';
import { UploadFileDialogComponent } from './main-application/upload-file-dialog/upload-file-dialog.component';
import { AddArtworkComponent } from './main-application/add-artwork/add-artwork.component';
import { HasRoleDirective } from './_helpers/has-role.directive';
import { QuoteAdminTableComponent } from './main-application/quote-admin-table/quote-admin-table.component';
import { DocumentTypeSelectionComponent } from './main-application/document-type-selection/document-type-selection.component';
import { ViewArtworkComponent } from './main-application/view-artwork/view-artwork.component';
import { ArtworkDetailsComponent } from './main-application/artwork-details/artwork-details.component';
import {TimelineModule} from 'primeng/timeline';
import { CustomersTableComponent } from './main-application/customers-table/customers-table.component';
import { UpdateCustomerDialogComponent } from './main-application/customers-table/update-customer/update-customer-dialog/update-customer-dialog.component';
import { SetPasswordComponent } from './auth/set-password/set-password/set-password.component';
import { FooterComponent } from './footer/footer/footer.component';
import { BulkOrdersComponent } from './main-application/bulk-orders/bulk-orders.component';
import { BulkOrdersFormComponent } from './main-application/bulk-orders/bulk-orders-form/bulk-orders-form.component';
import { BulkOrderDialogComponent } from './main-application/bulk-orders/bulk-orders-dialog/bulk-order-dialog/bulk-order-dialog.component';
import { ViewBulkOrdersComponent } from './main-application/bulk-orders/view-bulk-orders/view-bulk-orders/view-bulk-orders.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    SettingsComponent,
    QuotesTableComponent,
    FilesizePipe,
    TruncateFilenamePipe,
    LoginComponent,
    ForgotPasswordComponent,
    SettingsComponent,
    RegisterUserComponent,
    QuoteDetailComponent,
    UserDetailsComponent,
    UpdateUserComponent,
    UpdateRoleComponent,
    RoleDetailsComponent,
    CreateRoleComponent,
    RefreshComponent,
    ProductComponent,
    UpdateProductComponent,
    CreateProductComponent,
    GenerateQuoteComponent,
    CartComponent,
    ProfileComponent,
    UploadFileDialogComponent,
    AddArtworkComponent,
    HasRoleDirective,
    QuoteAdminTableComponent,
    DocumentTypeSelectionComponent,
    ViewArtworkComponent,
    ArtworkDetailsComponent,
    CustomersTableComponent,
    UpdateCustomerDialogComponent,
    SetPasswordComponent,
    FooterComponent,
    BulkOrdersComponent,
    BulkOrdersFormComponent,
    BulkOrderDialogComponent,
    ViewBulkOrdersComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeModule,
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    MenuModule,
    TimelineModule,
  ],
  providers: [
    HomeComponent,
    MessageService,
    ConfirmationService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
