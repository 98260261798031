import {
  Component,
  ElementRef,
  Input,
  NgModule,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../_services/authentication.service';
import { CartService } from '../_services/cart.service';
import { Role } from '../_models/role';
import { UserService } from '../_services/user.service';
import { StyleClass } from 'primeng/styleclass';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  mainitems: MenuItem[];
  profileitems: MenuItem[];
  menu: any;
  isMenuVisible: boolean = false;
  @Input() user: any = this.authenticationService.userValue;
  cartItems: any[] = [];
  cartLength: any;
  roles: typeof Role = Role;
  user$ = this.authenticationService.user;
  @Input() menuItems: any[] = [];
  filteredMenuItems: any[] = [];
  menuItem: any;
  currentUser: any;
  hasSubChild: boolean = false;
  currentYear: number = new Date().getFullYear();
  hasAccess: boolean = false;
  userRole: any;

  showFiller = false;
  isVisible: boolean = false;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService,
    private cartService: CartService,
    public userService: UserService
  ) {
    this.user = this.authenticationService.userValue;

    if (this.user) {
      this.mainitems = [
        { label: 'Home', routerLink: '' },
        { label: 'Get Quote', routerLink: 'getquote' },
      ];
    } else {
      this.mainitems = [
        // { label: 'Home', routerLink: '/' },
        // { label: 'Things To Do', routerLink: 'thingstodo' },
        // { label: 'Table', routerLink: 'table' },
        { label: 'Login', routerLink: 'login' },
      ];
    }

    this.profileitems = [
      {
        label: 'Logout',
        command: () => {
          this.authenticationService.logout();
        },
      },
    ];
  }

  ngOnInit(): void {
    this.cartService.cartItems$.subscribe((items) => {
      this.cartItems = items;
      this.cartLength = this.cartItems.length;
    });

    

    if(this.user) {
      this.userService.getCurrentLoggedInUser();
    }

    if (Role.Admin ||  Role.Customer_ZPL || Role.Basic_ZPL || Role.Customer_PIFSA) {
      this.hasAccess = true;
    }else{
      this.hasAccess = false;
    }

    this.userService.currentUser$.subscribe((user) => {
      this.userRole = user?.role?.name;
    });
    

    this.menuItems = [
      {
        label: 'Home',
        routerLink: '/',
        icon: 'pi pi-home',
        rolesWithAccess: [Role.Admin, Role.Customer_ZPL, Role.Basic_ZPL, Role.Customer_PIFSA],
      },
      {
        label: 'Actions',
        icon: 'pi pi-th-large',
        rolesWithAccess: [Role.Admin, Role.Customer_ZPL, Role.Customer_PIFSA],
        childMenuItem: [
          {
            label: 'Generate',
            icon: 'pi pi-plus-circle',
            rolesWithAccess: [Role.Admin, Role.Customer_ZPL, Role.Customer_PIFSA],
            childSubItem: [
              {
                label: 'Quote',
                routerLink: 'generatequote',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Customer_ZPL],
              },
              {
                label: 'Artwork',
                routerLink: 'artwork',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Customer_PIFSA],
              },
              {
                label: 'Bulk Orders',
                routerLink: 'bulk-orders',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin],
              },
              // {
              //   label: 'Automated Imposition',
              //   icon: 'pi pi-asterisk',
              //   rolesWithAccess: [Role.Admin, Role.BackOffice, Role.Basic],
              // },
              // {
              //   label: 'Quote and Imposition',
              //   icon: 'pi pi-asterisk',
              //   rolesWithAccess: [Role.Admin, Role.BackOffice, Role.Basic],
              // }
            ],
          },
          {
            label: 'View',
            icon: 'pi pi-eye',
            rolesWithAccess: [Role.Admin, Role.Customer_ZPL, Role.Customer_PIFSA],
            childSubItem: [
              {
                label: 'My Quotes',
                routerLink: 'viewmyquotes',
                icon: 'pi pi-shopping-cart',
                rolesWithAccess: [Role.Admin, Role.Customer_ZPL],
              },
              {
                label: 'My Artworks',
                routerLink: 'view-artwork',
                icon: 'pi pi-shopping-cart',
                rolesWithAccess: [Role.Admin, Role.Customer_PIFSA],
              }
            ],
          },
        ],
      },
      {
        label: 'Admin',
        icon: 'pi pi-chart-line',
        rolesWithAccess: [Role.Admin],
        childMenuItem: [
          {
            label: 'View Estimates',
            routerLink: 'quote-admin-table',
            icon: 'pi pi-chart-bar',
            rolesWithAccess: [Role.Admin],
          },
          // {
          //   label: 'View Impositions',
          //   icon: 'pi pi-users',
          //   rolesWithAccess: [Role.Admin],
          // },
          // {
          //   label: 'View Artworks',
          //   icon: 'pi pi-users',
          //   rolesWithAccess: [Role.Admin],
          // },
          {
            label: 'View Products',
            routerLink: 'product',
            icon: 'pi pi-cog',
            rolesWithAccess: [Role.Admin],
          },
          {
            label: 'View Customers',
            routerLink: 'customer-table',
            icon: 'pi pi-users',
            rolesWithAccess: [Role.Admin],
          },
          {
            label: 'View Bulk Orders',
            routerLink: 'view-bulk-orders',
            icon: 'pi pi-users',
            rolesWithAccess: [Role.Admin],
          }
        ],
      },
      {
        label: 'Settings',
        routerLink: 'settings',
        icon: 'pi pi-cog',
        rolesWithAccess: [Role.Admin, Role.Customer_ZPL, Role.Basic_ZPL, Role.Customer_PIFSA],
      },
    ];
  }

  toggleMenu(menuItem: any) {
    // Set the flag to true to show the menu
    if (menuItem.childMenuItem) {
      this.isVisible = !this.isVisible;
    }
  }

  isBurgerMenuVisible: boolean = true;

  toggleBurgerMenu() {
    this.isBurgerMenuVisible = !this.isBurgerMenuVisible;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );
}
