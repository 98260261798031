import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BulkOrderFormService {
  constructor(private fb: FormBuilder, private api: ApiService) {}

  createBulkOrder(orders: any) {
    return this.api.postAPI('bulk-orders/create-bulk-order', orders);
  }

  getBulkOrders() {
    return this.api.getAPI('bulk-orders/get-all-bulk-orders');
  }

  getBulkOrderForm() {
    return this.fb.group({
      refering_doctor: [''],
      mnem: [''],
      copy_doctor_1: [''],
      copy_doctor_2: [''],
      copy_doctor_3: [''],
      hospital_ward: [''],
      quantity: ['', [Validators.required, Validators.min(1)]],
      size: ['', [Validators.required]],
      biocross: [''],
    });
    // Listen for changes in the order type
  }

  // Method to dynamically update the Biocross field's validation based on order_type
  setBiocrossValidation(form: FormGroup) {
    const orderTypeControl = form.get('order_type');
    const ordersArray = form.get('orders') as FormArray; // Get the form array
  
    // Listen for changes in the order type
    orderTypeControl?.valueChanges.subscribe((orderType: any) => {
      // Loop through each form group in the array
      ordersArray.controls.forEach((group: AbstractControl) => {
        const biocrossControl = (group as FormGroup).get('biocross');
        
        // Apply or clear validators based on order type
        if (
          orderType.value === 'A5 Biocross' || 
          orderType.value === 'A4 Cashpath' || 
          orderType.value === 'A4 (Cyto, Downs, Gynae, Histo)' ||
          orderType.value === 'A4 Profile Biocross books'
        ) {
          biocrossControl?.setValidators(Validators.required); // Add required validator
        } else {
          biocrossControl?.clearValidators(); // Remove required validator
        }
  
        // Update validity status for each biocross control
        biocrossControl?.updateValueAndValidity();
  
        // Log validity to check if it works as expected
        console.log(`Biocross field in group ${group} valid:`, biocrossControl?.valid);
      });
    });
  }
  
}
