import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './main-application/home/home.component';
import { TableComponent } from './main-application/table/table.component';
import { AuthGuard } from './_helpers';
import { AppComponent } from './app.component';
import { SettingsComponent } from './main-application/settings/settings.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { QuotesTableComponent } from './main-application/quotes-table/quotes-table.component';
import { RefreshComponent } from './refresh/refresh.component';
import { ProductComponent } from './main-application/product/product.component';
import { CreateProductComponent } from './main-application/create-product/create-product.component';
import { GenerateQuoteComponent } from './main-application/generate-quote/generate-quote.component';
import { CartComponent } from './main-application/cart/cart.component';
import { ProfileComponent } from './main-application/profile/profile.component';
import { AddArtworkComponent } from './main-application/add-artwork/add-artwork.component';
import { QuoteAdminTableComponent } from './main-application/quote-admin-table/quote-admin-table.component';
import { ViewArtworkComponent } from './main-application/view-artwork/view-artwork.component';
import { CustomersTableComponent } from './main-application/customers-table/customers-table.component';
import { SetPasswordComponent } from './auth/set-password/set-password/set-password.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BulkOrdersComponent } from './main-application/bulk-orders/bulk-orders.component';
import { ViewBulkOrdersComponent } from './main-application/bulk-orders/view-bulk-orders/view-bulk-orders/view-bulk-orders.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl', 'customer-pifsa'] },
  },
  {
    path: 'createproduct',
    component: CreateProductComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin'] },
  },
  {
    path: 'product',
    component: ProductComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin'] },
  },
  { path: 'refresh', component: RefreshComponent },
  {
    path: 'viewmyquotes',
    component: QuotesTableComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl'] },
  },
  {
    path: 'generatequote',
    component: GenerateQuoteComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl'] },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl', 'customer-pifsa'] },
  },
  {
    path: 'cart',
    component: CartComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl', 'customer-pifsa'] },
  },
  {
    path: 'artwork',
    component: AddArtworkComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-pifsa'] },
  },
  {
    path: 'quote-admin-table',
    component: QuoteAdminTableComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin'] },
  },
  {
    path: 'view-artwork',
    component: ViewArtworkComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-pifsa'] },
  },
  {
    path: 'customer-table',
    component: CustomersTableComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin'] },
  },
  {
    path: 'bulk-orders',
    component: BulkOrdersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'basic-vermaak'] },
  },
  {
    path: 'view-bulk-orders',
    component: ViewBulkOrdersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin'] },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { role: ['admin', 'customer-zpl', 'customer-pifsa'] },
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'logout', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
