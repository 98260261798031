import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { confirmPasswordValidator } from '../validators/confirm-password.validator';

@Injectable({ providedIn: 'root' })
export class UserService {
  currentUserQuery$: any;
  currentUser: User = {}
  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>({});

  
  private membersSubject = new BehaviorSubject<User[]>([]);
  public members$ = this.membersSubject.asObservable();



  constructor(private http: HttpClient, private fb: FormBuilder) {
    
  }

  userForm = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    username: ['', Validators.required],
    role_id: ['', Validators.required],
    enabled: [true],
    scope: [''],
    password: ['', Validators.required],
    confirm_password: ['', [Validators.required]],
    customer: this.fb.group({
      id: [''],
    }),
  },{ validators: confirmPasswordValidator });

  updateUserForm = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    username: ['', Validators.required],
    role_id: [''],
    enabled: [true],
    scope: [''],
  })

  updateMembers(users: User[]) {
    this.membersSubject.next(users);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getMe() {
    return this.http.get<User>(`${environment.apiUrl}/users/me`);
  }

  getCurrentLoggedInUser() {
    this.currentUserQuery$ = this.getMe().subscribe((user) => {
      this.currentUser = user;
      this.currentUser$.next(user);
    });
  }

  clearCurrentLoggedInUser() {
    this.currentUser = {};
    this.currentUser$.next({});
  }

  postUser(user: any) {
    return this.http.post<any>(`${environment.apiUrl}/users/register`, user);
  }

  deleteUser(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }

  forgotPassword(email: string) {
    return this.http.post(
      `${environment.apiUrl}/users/forgot-password`,
      {},
      {
        params: {
          email: email,
        },
      }
    );
  }

  resetPassword(email: string, token: string, password: string) {
    return this.http.post(
      `${environment.apiUrl}/users/reset-password`,
      { new_password: password },
      {
        params: {
          reset_token: token,
          email: email,
        },
      }
    );
  }

  updateUser(userId: number, userData: any) {
    const url = `${environment.apiUrl}/users/${userId}`;
    return this.http.put(url, userData)
  }

  getRoles() {
    return this.http.get(`${environment.apiUrl}/role`);
  }
}
