export enum Role {
  Admin = "admin",
  Customer_ZPL = "customer-zpl",
  Basic_ZPL = "basic-zpl",
  Customer_PIFSA = "customer-pifsa",
  BASIC_VERMAAK = "basic-vermaak",
}

export interface IRole {
  id: number;
  name: Role;
  description: string;
}