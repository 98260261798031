<p-progressSpinner
  *ngIf="loadingData"
  styleClass="w-4rem h-4rem"
  strokeWidth="8"
  fill="var(--surface-ground)"
  animationDuration=".5s"
></p-progressSpinner>
<div *ngIf="errorLoadingData"></div>
<div
  class="surface-section px-4 py-4 md:px-1 lg:px-2"
  *ngIf="!loadingData && !errorLoadingData"
>
  <div class="grid">
    <form
      [formGroup]="this.userService.userForm"
      (ngSubmit)="updateUser(userData.id)"
    >
      <div class="col-12 lg:col-12">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12 md:col-6">
            <label for="first_name" class="font-medium text-900"
              >First Name</label
            >
            <input
              id="first_name"
              type="text"
              pInputText
              formControlName="first_name"
              [(ngModel)]="userData.first_name"
            />
            <div
              *ngIf="
                submitted && this.userService.userForm.get('first_name')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['first_name'] &&
                  f['first_name'].errors &&
                  f['first_name'].errors['required']
                "
                severity="error"
                text="* Please enter your first name"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="last_name" class="font-medium text-900"
              >Last Name</label
            >
            <input
              id="last_name"
              type="text"
              pInputText
              formControlName="last_name"
              [(ngModel)]="userData.last_name"
            />
            <div
              *ngIf="
                submitted && this.userService.userForm.get('last_name')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['last_name'] &&
                  f['last_name'].errors &&
                  f['last_name'].errors['required']
                "
                severity="error"
                text="* Please enter your last name"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="username" class="font-medium text-900">Username</label>
            <input
              id="username"
              type="text"
              pInputText
              formControlName="username"
              [(ngModel)]="userData.username"
            />
            <div
              *ngIf="
                submitted && this.userService.userForm.get('username')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['username'] &&
                  f['username'].errors &&
                  f['username'].errors['required']
                "
                severity="error"
                text="* Please enter a username"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>

          <div class="field mb-4 col-12 md:col-6">
            <label for="email" class="font-medium text-900">Email</label>
            <input
              id="email"
              type="email"
              pInputText
              formControlName="email"
              [(ngModel)]="userData.email"
            />
            <div
              *ngIf="
                submitted && this.userService.userForm.get('email')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['email'] &&
                  f['email'].errors &&
                  f['email'].errors['required']
                "
                severity="error"
                text="* Please enter a valid email"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="role" class="font-medium text-900">Role</label>

            <p-dropdown
              inputId="role"
              [options]="roles"
              optionLabel="name"
              optionValue="id"
              [showClear]="true"
              placeholder="Select a Role"
              formControlName="role_id"
            >
            </p-dropdown>
            <div
              *ngIf="
                submitted && this.userService.userForm.get('role_id')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['role_id'] &&
                  f['role_id'].errors &&
                  f['role_id'].errors['required']
                "
                severity="error"
                text="* Please select a role"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6" formGroupName="customer">
            <label for="customer" class="font-medium text-900">Customer</label>

            <p-dropdown
              inputId="customer"
              [options]="customers"
              optionLabel="company_name"
              optionValue="id"
              [showClear]="true"
              placeholder="Select a Customer"
              [(ngModel)]="selectedCustomer"
              formControlName="id"
              [virtualScroll]="true"
              [virtualScrollItemSize]="38"
              [filter]="true"
              filterBy="company_name"
            >
            </p-dropdown>
          </div>

          <div class="col-12">
            <button
              [loading]="loading"
              pButton
              pRipple
              type="submit"
              label="Save Changes"
              class="w-auto mt-3"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
