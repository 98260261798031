import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Role } from 'src/app/_models/role';
import { UserService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { CustomerService } from 'src/app/_services/customer.service';
import { MessageService } from 'primeng/api';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit {
  roles: any[] = [];
  customers: any[] = [];
  selectedCustomer: any;
  submitted: boolean = false;
  loading: boolean = false;

  

  constructor(
    public userService: UserService,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public ref: DynamicDialogRef,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.userService.userForm.reset();

    this.userService.getRoles().subscribe((roles: any) => {
      this.roles = roles;
    });

    this.customerService.getAllCustomers().subscribe((customers) => {
      this.customers = customers;
    });
  }

  get f() {
    return this.userService.userForm.controls;
  
  }

  onSubmitUserForm() {
    this.loading = true;
    this.submitted = true;
    if (this.userService.userForm.invalid) {
      this.loading = false;      
      return;
    }

    if (this.userService.userForm.valid) {
      this.userService.postUser(this.userService.userForm.value).subscribe(
        (res) => {
          this.userService.getAll().subscribe(
            (users) => {
              this.userService.updateMembers(users);
            },
            (error: any) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'User Creation Failed',
                key: 'register',
              });
            }
          );
          this.loading = false;
          this.userService.userForm.reset();
          this.ref.close(res);
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'User Creation Failed',
            key: 'register',
          });
        }
      );
    }
  }
}
