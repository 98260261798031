import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';

@Component({
  selector: 'app-view-bulk-orders',
  templateUrl: './view-bulk-orders.component.html',
  styleUrls: ['./view-bulk-orders.component.scss']
})
export class ViewBulkOrdersComponent implements OnInit {
  bulkOrders: any[] = [];
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;
  
  columns = [
    { field: 'order_type', header: 'Order Type' },
    { field: 'purchase_order', header: 'Purchase Order' },
    { field: 'order_number', header: 'Order Number' },
    { field: 'refering_doctor', header: 'Refering Doctor' },
    { field: 'mnem', header: 'Mnem' },
    { field: 'copy_doctor_1', header: 'Copy Doctor 1' },
    { field: 'copy_doctor_2', header: 'Copy Doctor 2' },
    { field: 'copy_doctor_3', header: 'Copy Doctor 3' },
    { field: 'hospital_ward', header: 'Hospital Ward' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'size', header: 'Size' },
    { field: 'biocross', header: 'Biocross' },
  ];

  constructor(
    private bulkOrderFormService: BulkOrderFormService
  ) { }

  ngOnInit(): void {
    this.getBulkOrders();
    
  }

  getBulkOrders() {
    this.bulkOrderFormService.getBulkOrders().subscribe(
      (response: any) => {
        this.bulkOrders = response.body;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.columns.length;
  }

}
